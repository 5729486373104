/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/loadsh@0.0.4/lodash.min.js
 * - /npm/jquery@3.6.0/dist/jquery.min.js
 * - /npm/picturefill@3.0.3/dist/picturefill.min.js
 * - /npm/gsap@3.7.0/dist/gsap.min.js
 * - /npm/scrollmagic@2.0.7/scrollmagic/minified/ScrollMagic.min.js
 * - /npm/scrollmagic@2.0.7/scrollmagic/minified/plugins/debug.addIndicators.min.js
 * - /npm/scrollmagic@2.0.7/scrollmagic/minified/plugins/animation.gsap.min.js
 * - /npm/slick-carousel@1.8.1/slick/slick.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
